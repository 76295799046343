import React from "react";
import CashReinvesting from "../components/cash-reinvesting";
export const query = graphql`
  query indexPageQueryPortfolioReinvestingQT {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityPost(
      limit: 3
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;
const CashReinvestingQTPage = props => {
  return <CashReinvesting questrade={true} {...props} />;
};
export default CashReinvestingQTPage;
